<template>
  <div class="meeting_agree_content">
    <div class="meeting_agree_content_main" v-html="detail || ''"></div>
  </div>
</template>

<script>
  export default {
    name: 'MeetingAgreePreviewContent',
    data: () => ({
      detail: ''
    }),
    created () {
      this.getDetail()
    },
    methods: {
      getDetail () {
        this.$api.meeting.getMeetingAgreementContent({ id: this.$route.query.id }).then(res => {
          if (!res.code) {
            this.detail = res.data || ''
          }
        })
      },
    }
  }
</script>

<style lang="less">
.meeting_agree_content {
  min-height: 100vh;
  background-color: rgba(245, 246, 247, 1);
  box-sizing: border-box;
  padding: 14px 16px;
  .meeting_agree_content_main {
    box-sizing: border-box;
    background-color: #FFF;
    border-radius: 6px;
    width: 100%;
    padding: 14px 10px;
    overflow: hidden;
    img {
      max-width: 100% !important;
    }
  }
  .van-button {
    height: 46px;
    border-radius: 3px;
    .van-button__text {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      color: #000000;
    }
  }
}
</style>